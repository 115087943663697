import type { QueryState } from '../types/queryState';
import { DEFAULT_QUERY_STATE } from '../types/queryState';
import type { EquipmentCategory } from '../types/api';
import { RANGE_CONFIGS, getRangeDefaults } from '../shared/range-config.js';

interface ApiParams {
  page: number;
  limit: number;
  search?: string;
  make?: string[];
  modelFamily?: string[];
  model?: string[];
  showZeroPrice?: boolean;
  showSold?: boolean;
  priceMin?: number;
  priceMax?: number;
  yearMin?: number;
  yearMax?: number;
  engineTimeMin?: number;
  engineTimeMax?: number;
  usefulLoadMin?: number;
  usefulLoadMax?: number;
  sort?: string;
  'equipment.*.installed'?: boolean;
  equipmentCategories?: string[];
  condition?: string;
  saleStatus?: string;
  intendedUse?: string[];
  category?: string[];
}

type RangeKey = 'priceRange' | 'yearRange' | 'engineTimeRange' | 'usefulLoadRange';

interface RangeConfig {
  key: RangeKey;
  min: number;
  max: number;
}

// Convert the RANGE_CONFIGS object to the simplified format needed for API transforms
const apiRangeConfigs: RangeConfig[] = [
  { key: 'priceRange', min: RANGE_CONFIGS.priceRange.min, max: RANGE_CONFIGS.priceRange.max },
  { key: 'yearRange', min: RANGE_CONFIGS.yearRange.min, max: RANGE_CONFIGS.yearRange.max },
  { key: 'engineTimeRange', min: RANGE_CONFIGS.engineTimeRange.min, max: RANGE_CONFIGS.engineTimeRange.max },
  { key: 'usefulLoadRange', min: RANGE_CONFIGS.usefulLoadRange.min, max: RANGE_CONFIGS.usefulLoadRange.max }
];

/**
 * Validates and clamps a range value within the configured limits
 */
function validateRange(range: [number, number] | undefined, config: RangeConfig): [number, number] | undefined {
  if (!range?.length) return undefined;
  const [min, max] = range;
  return [
    Math.max(Math.min(min, config.max), config.min),
    Math.min(Math.max(max, config.min), config.max)
  ];
}

/**
 * Transforms a range tuple into a comma-separated string if valid
 */
function transformRange(range: [number, number] | undefined): string | undefined {
  return range?.length === 2 ? range.join(',') : undefined;
}

/**
 * Checks if a range has non-default values
 */
function isRangeActive(range: [number, number] | undefined, config: RangeConfig): boolean {
  if (!range?.length) return false;
  const [min, max] = range;
  const fullConfig = RANGE_CONFIGS[config.key]; // Get the complete config with all properties
  
  // Check if the min is above default
  const isMinActive = min > config.min;
  
  // Check if max is below default, considering includeAboveMax setting
  let isMaxActive = false;
  if (fullConfig.includeAboveMax) {
    // When includeAboveMax is true, only consider max active if below default
    isMaxActive = max < config.max;
  } else {
    // When includeAboveMax is false, max is active if not equal to default
    isMaxActive = max !== config.max;
  }
  
  return isMinActive || isMaxActive;
}

/**
 * Transforms query state into API parameters with minimal transformations
 */
export function transformQueryToApiParams(queryState: Partial<QueryState>): ApiParams {
  // Start with the essential pagination params
  const params: ApiParams = {
    page: queryState.page ?? 1,
    limit: queryState.itemsPerPage ?? 20
  };

  // Direct assignments - only include defined values
  if (queryState.search) params.search = queryState.search;
  
  // For array parameters, join the values and sort them to ensure consistent ordering
  if (queryState.makes?.length) {
    // Sort values alphanumerically to ensure cache key consistency
    params.make = [queryState.makes.slice().sort().join(',')];
  }
  
  if (queryState.modelFamilies?.length) {
    // Sort values alphanumerically to ensure cache key consistency
    params.modelFamily = [queryState.modelFamilies.slice().sort().join(',')];
  }
  
  if (queryState.models?.length) {
    // Sort values alphanumerically to ensure cache key consistency
    params.model = [queryState.models.slice().sort().join(',')];
  }
  
  if (queryState.showZeroPrice) params.showZeroPrice = true;
  if (queryState.showSold !== undefined) params.showSold = queryState.showSold;
  if (queryState.sort) params.sort = queryState.sort;
  
  // Handle new filter parameters
  if (queryState.condition) params.condition = queryState.condition;
  if (queryState.saleStatus) params.saleStatus = queryState.saleStatus;
  
  if (queryState.intendedUse?.length) {
    // Sort values alphanumerically to ensure cache key consistency
    params.intendedUse = [queryState.intendedUse.slice().sort().join(',')];
  }
  
  if (queryState.category) {
    params.category = [queryState.category];
  }

  // Handle ranges consistently - only add non-default ranges
  const ranges: Record<RangeKey, [number, number] | undefined> = {
    priceRange: queryState.priceRange,
    yearRange: queryState.yearRange,
    engineTimeRange: queryState.engineTimeRange,
    usefulLoadRange: queryState.usefulLoadRange
  };

  // Transform each range with validation and skip default values
  apiRangeConfigs.forEach(config => {
    const validatedRange = validateRange(ranges[config.key], config);
    
    // Skip adding the parameter if it's the default range
    let isDefaultRange = false;
    
    if (validatedRange) {
      const fullConfig = RANGE_CONFIGS[config.key]; // Get the complete config with all properties
      const [min, max] = validatedRange;
      
      // Check if min is at default
      const isMinDefault = min === config.min;
      
      // Check if max is at default, based on includeAboveMax setting
      let isMaxDefault = false;
      if (fullConfig.includeAboveMax) {
        // When includeAboveMax is true, consider max default if at or above default max
        isMaxDefault = max >= config.max;
      } else {
        // When includeAboveMax is false, max is only default if exactly at default
        isMaxDefault = max === config.max;
      }
      
      // Range is default if both min and max are at default values
      isDefaultRange = isMinDefault && isMaxDefault;
      
      // Add min/max parameters separately, but only if they're not at default values
      if (!isMinDefault) {
        // Add min parameter - convert to the specific parameter name
        if (config.key === 'priceRange') {
          params.priceMin = min;
        } else if (config.key === 'yearRange') {
          params.yearMin = min;
        } else if (config.key === 'engineTimeRange') {
          params.engineTimeMin = min;
        } else if (config.key === 'usefulLoadRange') {
          params.usefulLoadMin = min;
        }
      }
      
      if (!isMaxDefault) {
        // Add max parameter - convert to the specific parameter name
        if (config.key === 'priceRange') {
          params.priceMax = max;
        } else if (config.key === 'yearRange') {
          params.yearMax = max;
        } else if (config.key === 'engineTimeRange') {
          params.engineTimeMax = max;
        } else if (config.key === 'usefulLoadRange') {
          params.usefulLoadMax = max;
        }
      }
    }
  });

  // Handle equipment filter
  if (queryState.equipment) {
    const selectedEquipment = Object.entries(queryState.equipment)
      .filter(([_, isSelected]) => isSelected)
      .map(([category]) => category);

    if (selectedEquipment.length > 0) {
      // Reinstate the original parameters but use the cleaned-up selectedEquipment list
      params['equipment.*.installed'] = true;
      params.equipmentCategories = [selectedEquipment.slice().sort().join(',')];
    }
  }

  return params;
}

/**
 * Checks if any filters are active in the query state
 */
export function hasActiveFilters(queryState: Partial<QueryState>): boolean {
  if (!queryState) return false;

  // Check basic filters
  if (queryState.search || 
      queryState.makes?.length || 
      queryState.modelFamilies?.length ||
      queryState.models?.length || 
      queryState.showZeroPrice) {
    return true;
  }

  // Check equipment filters
  if (queryState.equipment && Object.values(queryState.equipment).some(value => value)) {
    return true;
  }
  
  // Check new filters
  if (queryState.condition ||
      queryState.saleStatus ||
      queryState.intendedUse?.length ||
      queryState.category?.length) {
    return true;
  }

  // Check ranges
  return apiRangeConfigs.some(config => 
    isRangeActive(queryState[config.key], config)
  );
} 