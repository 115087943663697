import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Icon } from './Icon';
import { api } from '../api/client.js';
import type { ProductListing, CardMetric, MetricOption, Option, EquipmentCategory } from '../types/api.js';
import { equipmentCategories } from '../types/api.js';
import { SearchAndFilter } from './SearchAndFilter.js';
import { ListingCard } from './ListingCard.js';
import { FilterUpdateIndicator } from './FilterUpdateIndicator.js';
import { Suspense } from 'react';
import { useListingContext } from '../context/ListingContext';
import { ErrorBoundary } from './ErrorBoundary';
import { LoadingIndicator } from './LoadingIndicator';
import { useListingQueryState } from '../hooks/useListingQueryState';
import type { QueryState, SortOption } from '../types/queryState';
import { isCardMetric } from '../types/queryState';
import { useQueryStates, parseAsArrayOf, parseAsString, parseAsBoolean, parseAsJson } from 'nuqs';
import { RANGE_CONFIGS } from '../shared/range-config';
import { FavoritesManager } from '../utils/favorites';
import { FavoriteButton } from './FavoriteButton';
import { FilterModal } from './FilterModal';
import { SelectDropdown } from './SelectDropdown';
import { motion, AnimatePresence } from 'framer-motion';
import { FavoritesCounter } from './FavoritesCounter';
import { DarkModeToggle } from './DarkModeToggle';
import { cn, buttonStyles } from '../lib/utils';
import { Pagination } from './Pagination';
import { ListingDetailModal } from './ListingDetailModal.js';
import { ListingImagesProvider, useListingImages } from '../contexts/ListingImagesContext';
import { ImageModal } from './ImageModal.js';
import { useColumnCount } from '../hooks/useColumnCount';
import { DEFAULT_QUERY_STATE } from '../types/queryState';
import { validateSort, validateColumnCount } from '../lib/parsers';
import { ExpandableSearch } from './ExpandableSearch';
import { animations } from '../lib/animation-config';
import { useFilterHandlers } from '../hooks/useFilterHandlers';
import './PropellerAnimation.css';

const DEFAULT_METRICS: CardMetric[] = [
  'registration',
  'year',
  'totalTime',
  'engineTime',
  'propTime',
  'flightRules'
];

const availableMetrics: MetricOption[] = [
  // Basic Info metrics
  { key: 'registration', label: 'Tail #', group: 'basic' },
  { key: 'year', label: 'Year', group: 'basic' },
  { key: 'totalTime', label: 'Total Time', group: 'basic' },
  { key: 'engineTime', label: 'Engine Time', group: 'basic' },
  { key: 'propTime', label: 'Prop Time', group: 'basic' },
  { key: 'flightRules', label: 'Flight Rules', group: 'basic' },
  { key: 'make', label: 'Make', group: 'basic' },
  { key: 'model', label: 'Model', group: 'basic' },
  { key: 'serialNumber', label: 'Serial #', group: 'basic' },
  { key: 'usefulLoad', label: 'Useful Load', group: 'basic' },
  // Equipment metrics
  ...Object.entries(equipmentCategories).map(([key, label]) => ({
    key: `equipment.${key}` as CardMetric,
    label,
    group: 'equipment' as const
  }))
];

// Update the interface for API response to include modelFamily
interface ExtendedListingsApiResponse {
  listings: ProductListing[];
  total: number;
  totalPages: number;
  availableFilters: {
    makes: Array<Option>;
    model: Array<Option & { make: string; modelFamily?: string }>;
    modelFamily?: Array<Option & { make: string }>;
    equipment: Record<string, Option[]>;
    prices: Array<{ min: number; max: number }>;
    conditions?: Array<Option>;
    saleStatuses?: Array<Option>;
    intendedUses?: Array<Option>;
    categories?: Array<Option>;
  };
}

// Define sortOptions for mobile layout
const sortOptions = [
  { value: 'relevance', label: 'Most Relevant' },
  { value: 'price-asc', label: 'Price: Low to High' },
  { value: 'price-desc', label: 'Price: High to Low' },
  { value: 'year-asc', label: 'Year: Oldest' },
  { value: 'year-desc', label: 'Year: Newest' },
  { value: 'date-listed', label: 'Recently Listed' },
  { value: 'updated-desc', label: 'Recently Updated' },
  { value: 'updated-asc', label: 'Oldest Updates' },
];

// Helper function to format sort options
const formatSortOptionLabel = (option: { value: string; label: string }) => (
  <div className="flex items-center py-2 px-3">
    <span>{option.label}</span>
  </div>
);

// Function to convert sale status string to Option objects
const getSaleStatusOptions = (saleStatusString: string, availableOptions: Option[]): Option[] => {
  if (!saleStatusString) return [];
  
  const statusValues = saleStatusString.split(',');
  return statusValues
    .map(value => {
      const option = availableOptions.find(opt => opt.value === value);
      return option || null;
    })
    .filter(Boolean) as Option[];
};

export const AircraftSearch = () => {
  const { queryState, setQueryState, isFiltered, clearFilters } = useListingQueryState();
  const [columnCount, setColumnCount] = useColumnCount();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Listing detail modal state
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [currentDetailId, setCurrentDetailId] = useState<string | null>(null);
  const [lastViewedCardRect, setLastViewedCardRect] = useState<DOMRect | null>(null);
  // Add a state to keep track of the recently closed listing for animation
  const [recentlyClosedId, setRecentlyClosedId] = useState<string | null>(null);
  
  // Simple scrollToTop function
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  // Define modal handlers outside of useEffect for broader scope access
  const handleCloseDetailModal = useCallback((e?: CustomEvent<{ fromBackButton?: boolean }>) => {
    // Preserve the current detail ID for animation
    if (currentDetailId) {
      setRecentlyClosedId(currentDetailId);
    }
    
    // Close modal
    setIsDetailModalOpen(false);
    
    // Check if this is a result of a popstate event or direct close action
    // Either from the event detail or the history state
    const fromBackButton = 
      (e && e.detail && e.detail.fromBackButton) || 
      (window.history.state && window.history.state.isBackNavigation === true);
    
    if (!fromBackButton) {
      // If this is a direct close (not from back button), we want to go back to previous state
      window.history.back();
    } else {
      // If we're here from a back button press already, just update the URL without adding history
      const url = new URL(window.location.href);
      url.searchParams.delete('detailId');
      window.history.replaceState({ isBackNavigation: false }, '', url.toString());
    }
    
    // Use a stepped timing for the animation sequence
    // First clear the current ID immediately while maintaining the recently closed ID
    // This will trigger the isSelected state change in the card sooner
    setCurrentDetailId(null);
    
    // Finally clear the recently closed ID after animation completes
    setTimeout(() => {
      setRecentlyClosedId(null);
    }, 0); // Match animation duration plus a small buffer
  }, [currentDetailId]);

  const handleOpenDetailModal = useCallback((e: CustomEvent<{ id: string, sourceRect: DOMRect | null }>) => {
    const { id, sourceRect } = e.detail;
    
    // Update modal state
    setLastViewedCardRect(sourceRect);
    setCurrentDetailId(id);
    setIsDetailModalOpen(true);
    // Clear any previously closed ID
    setRecentlyClosedId(null);
    
    // Update URL by pushing a new history state instead of replacing
    // This ensures proper back button behavior
    const url = new URL(window.location.href);
    url.searchParams.set('detailId', id);
    window.history.pushState({ detailId: id, isModalView: true }, '', url.toString());
  }, []);

  // Handle browser back/forward
  const handlePopState = useCallback((event: PopStateEvent) => {
    const state = event.state as { detailId?: string, isModalView?: boolean, isBackNavigation?: boolean } | null;
    const detailId = new URLSearchParams(window.location.search).get('detailId');
    
    // Mark this state as resulting from a back/forward navigation
    if (state) {
      window.history.replaceState(
        { ...state, isBackNavigation: true }, 
        '', 
        window.location.href
      );
    }
    
    if (detailId || (state && state.detailId)) {
      // Opening modal from back/forward navigation
      const id = detailId || state.detailId;
      if (currentDetailId !== id || !isDetailModalOpen) {
        setCurrentDetailId(id!);
        setIsDetailModalOpen(true);
      }
    } else {
      // Closing modal from back button
      if (isDetailModalOpen) {
        setIsDetailModalOpen(false);
        setCurrentDetailId(null);
        setRecentlyClosedId(null); // Clear any animation state
      }
    }
  }, [currentDetailId, isDetailModalOpen]);
  
  // Check URL on mount for any detail parameter
  useEffect(() => {
    const detailId = searchParams.get('detailId');
    if (detailId) {
      setCurrentDetailId(detailId);
      setIsDetailModalOpen(true);
    }
  }, [searchParams]);
  
  // Listen for modal open/close events
  useEffect(() => {
    window.addEventListener('openDetailModal', handleOpenDetailModal as EventListener);
    window.addEventListener('closeDetailModal', handleCloseDetailModal as EventListener);
    window.addEventListener('popstate', handlePopState);
    
    return () => {
      window.removeEventListener('openDetailModal', handleOpenDetailModal as EventListener);
      window.removeEventListener('closeDetailModal', handleCloseDetailModal as EventListener);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleCloseDetailModal, handleOpenDetailModal, handlePopState]);
  
  // UI State
  const [availableFilters, setAvailableFilters] = useState<{
    makes: Option[];
    modelFamilies: Array<Option & { make: string }>;
    models: Array<Option & { make: string, modelFamily?: string }>;
    years: { min: number; max: number };
    conditions?: Option[];
    saleStatuses?: Option[];
    intendedUses?: Option[];
    categories?: Option[];
  }>({
    makes: [],
    modelFamilies: [],
    models: [],
    years: { min: 0, max: 0 },
    conditions: [],
    saleStatuses: [],
    intendedUses: [],
    categories: []
  });

  // Filter modal state
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  
  // Metrics configuration state
  const [isMetricsConfigOpen, setIsMetricsConfigOpen] = useState(false);
  
  // Image modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentListing, setCurrentListing] = useState<ProductListing | null>(null);
  const [modalIndex, setModalIndex] = useState(0);
  
  // Component State
  const [isInitialLoading, setIsInitialLoading] = useState(true); // For initial page load only
  const [isLoading, setIsLoading] = useState(true); // For all data fetches
  const [error, setError] = useState<string | null>(null);
  const [listings, setListings] = useState<ProductListing[]>([]);
  const { updateMetadata, metadata } = useListingContext();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false); // State to track complete loading
  const [showSpinner, setShowSpinner] = useState(true); // Explicit state for spinner visibility - initially true
  
  // Flag to track if we should skip data fetching for the current state update
  const skipFetchRef = useRef(false);
  // Timestamp to track when loading started
  const loadStartTimeRef = useRef<number>(0);

  // Convert UI Preferences to URL state
  const [uiState, setUiState] = useQueryStates(
    {
      visibleMetrics: parseAsArrayOf(parseAsString).withDefault(DEFAULT_METRICS),
      showMetricsConfig: parseAsString.withDefault('false'),
      showFilter: parseAsBoolean.withDefault(false),
      sort: parseAsJson(validateSort).withDefault(DEFAULT_QUERY_STATE.sort),
      columnCount: parseAsJson(validateColumnCount).withDefault(DEFAULT_QUERY_STATE.columnCount),
      showSold: parseAsBoolean.withDefault(false)
    },
    {
      history: 'replace',
      shallow: true
    }
  );

  // State for favorites count
  const [favoritesCount, setFavoritesCount] = useState(0);
  
  // Filter change handler
  const handleFilterChange = (newFilters: Partial<QueryState>) => {
    console.log('New filters:', newFilters);
    
    // Validate sort value if present
    const sort = newFilters.sort;
    if (sort && !['relevance', 'price-asc', 'price-desc', 'year-asc', 'year-desc', 'date-listed', 'updated-desc', 'updated-asc'].includes(sort)) {
      console.warn(`Invalid sort value: ${sort}, defaulting to 'relevance'`);
      newFilters.sort = 'relevance' as SortOption;
    }

    // Create a type-safe copy of the filters
    const safeFilters = { ...newFilters };
    
    setQueryState(prev => {
      // Create a new state object with the updated values
      const newState = {
        ...prev,
        ...safeFilters,
      };
      
      // Reset page to 1 for filter changes if not explicitly changing page
      if (!('page' in safeFilters)) {
        newState.page = 1;
      }
      
      return newState;
    });
  };
  
  // Use our centralized filter handlers with the adjusted QueryState type
  const filterHandlers = useFilterHandlers(
    queryState as unknown as QueryState, 
    handleFilterChange, 
    availableFilters
  );
  
  // Handle search input directly
  const handleSearch = (searchTerm: string) => {
    console.log('Search term:', searchTerm);
    handleFilterChange({ search: searchTerm });
  };

  // Update favorites count when component mounts and when a favorite is added/removed
  useEffect(() => {
    const updateFavoritesCount = () => {
      setFavoritesCount(FavoritesManager.getFavoritesCount());
    };
    
    // Update count initially
    updateFavoritesCount();
    
    // Listen for storage events to update count when favorites change
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'product-favorites') {
        updateFavoritesCount();
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    
    // Custom event for when favorites change within the same window
    const handleFavoritesChange = () => {
      updateFavoritesCount();
    };
    
    window.addEventListener('favoritesChanged', handleFavoritesChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('favoritesChanged', handleFavoritesChange);
    };
  }, []);

  // Handlers for UI state
  const handleVisibleMetricsChange = (metrics: string[]) => {
    const validMetrics = metrics.filter(isCardMetric);
    setUiState(prev => ({ ...prev, visibleMetrics: validMetrics }));
  };

  const handleMetricsConfigToggle = (show: boolean) => {
    setUiState(prev => ({ ...prev, showMetricsConfig: show.toString() }));
  };

  const handleSortChange = (sort: SortOption) => {
    handleFilterChange({ sort });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > metadata.totalPages) return;
    
    setQueryState(prev => ({
      ...prev,
      page: newPage
    }));
    scrollToTop();
  };

  const handleImageClick = (listing: ProductListing, index: number) => {
    setCurrentListing(listing);
    setModalIndex(index);
    setIsModalOpen(true);
  };

  // Create options for filter dropdowns
  const makeOptions = useMemo(() => {
    return queryState.makes.map(value => {
      const option = availableFilters.makes.find(make => make.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.makes, availableFilters.makes]);

  const modelFamilyOptions = useMemo(() => {
    return queryState.modelFamilies.map(value => {
      const option = availableFilters.modelFamilies.find(modelFamily => modelFamily.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.modelFamilies, availableFilters.modelFamilies]);

  const modelOptions = useMemo(() => {
    return queryState.models.map(value => {
      const option = availableFilters.models.find(model => model.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.models, availableFilters.models]);

  // Create options for the new filter dropdowns
  const conditionOption = useMemo(() => {
    if (!queryState.condition) return null;
    
    return {
      value: queryState.condition,
      label: queryState.condition,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.condition]);

  const saleStatusOption = useMemo(() => {
    if (!queryState.saleStatus) return null;
    
    return {
      value: queryState.saleStatus,
      label: queryState.saleStatus,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.saleStatus]);

  const intendedUseOptions = useMemo(() => {
    return queryState.intendedUse.map(value => ({
      value,
      label: value,
      count: 0,
      zeroCount: 0
    }));
  }, [queryState.intendedUse]);

  const categoryOption = useMemo(() => {
    if (!queryState.category) return null;
    
    return {
      value: queryState.category,
      label: queryState.category,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.category]);

  // Add a simple effect to load images for all listings at once
  const { loadImagesForListings } = useListingImages();
  useEffect(() => {
    if (listings.length > 0 && !isInitialLoading && !isUpdating) {
      // Simple direct call - no throttling or complex logic needed
      const listingIds = listings.map(listing => listing.aircraftId || listing._id);
      loadImagesForListings(listingIds);
    }
  }, [listings, loadImagesForListings, isInitialLoading, isUpdating]);

  // Add a useEffect to initialize properly and handle initial page load
  useEffect(() => {
    // Fetch listings will handle initial loading too
    // Set initial loading state for spinner
    setShowSpinner(true);
    setIsLoadingComplete(false);
    
    // After component mounts, we'll consider any future updates "non-initial"
    return () => {
      setIsInitialLoading(false);
    };
  }, []);

  // Fetch listings whenever queryState changes
  useEffect(() => {
    // If we should skip fetching for this update, reset the flag and return
    if (skipFetchRef.current) {
      skipFetchRef.current = false;
      return;
    }
    
    const fetchListings = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      
      // Record the start time of the loading operation
      loadStartTimeRef.current = Date.now();

      // Set updating state immediately
      setIsUpdating(true);
      setIsLoadingComplete(false); // Reset loading complete status
      setShowSpinner(true); // Always show spinner

      try {
        const response = await api.listings.getAll(queryState as unknown as Partial<QueryState>, {
          signal: abortControllerRef.current.signal
        });
        
        // Handle the response safely
        if (response.error) {
          setError(response.error);
        } else if (response.data) {
          // Use the data without type assertion
          const data = response.data;
          
          // Only update listings if data has actually changed
          // This prevents unnecessary rerenders and animation flickers
          if (JSON.stringify(data.listings.map(l => l._id)) !== JSON.stringify(listings.map(l => l._id))) {
            setListings(data.listings);
          }
          
          updateMetadata({
            total: data.total,
            totalPages: Math.ceil(data.total / queryState.itemsPerPage)
          });

          if (data.availableFilters) {
            const { makes = [], model = [] } = data.availableFilters;
            // Safely access modelFamily with a fallback to empty array
            // @ts-ignore - modelFamily might not exist in the API response type
            const modelFamily = data.availableFilters.modelFamily || [];
            
            // Type assertion for the API response data
            const apiFilters = data.availableFilters as unknown as ExtendedListingsApiResponse['availableFilters'];
            
            setAvailableFilters({
              makes: makes.map(make => ({
                value: make.value,
                label: make.label,
                count: make.count || 0,
                zeroCount: make.zeroCount || 0
              })),
              modelFamilies: modelFamily.map(family => ({
                value: family.value,
                label: family.label,
                count: family.count || 0,
                zeroCount: family.zeroCount || 0,
                make: family.make
              })),
              models: model.map(model => {
                // Safely access modelFamily property with a fallback
                // @ts-ignore - modelFamily might not exist in the model type
                const modelFamilyValue = model.modelFamily || undefined;
                
                return {
                  value: model.value,
                  label: model.label,
                  count: model.count || 0,
                  zeroCount: model.zeroCount || 0,
                  make: model.make,
                  modelFamily: modelFamilyValue
                };
              }),
              years: { min: RANGE_CONFIGS.yearRange.min, max: RANGE_CONFIGS.yearRange.max },
              
              // Add the new filter options from the API response
              conditions: apiFilters.conditions?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              saleStatuses: apiFilters.saleStatuses?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              intendedUses: apiFilters.intendedUses?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              categories: apiFilters.categories?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || []
            });
          }

          setError(null);
        }
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') return;
        setError(err instanceof Error ? err.message : 'Failed to fetch listings');
      } finally {
        // Calculate how long the operation took
        const elapsed = Date.now() - loadStartTimeRef.current;
        // Ensure spinner shows for at least 1000ms
        const minLoadingTime = 1000;
        const remainingTime = Math.max(0, minLoadingTime - elapsed);
        
        // Wait at least the minimum loading time before starting to hide spinner
        setTimeout(() => {
          setIsLoading(false);
          setIsInitialLoading(false);
          
          // Keep the updating indicator visible a bit longer
          setTimeout(() => {
            setShowSpinner(false); // Hide spinner
            setIsUpdating(false);
            abortControllerRef.current = null;
            
            // Set loading complete after spinner fade-out
            setTimeout(() => {
              setIsLoadingComplete(true);
            }, 300);
          }, 500); 
        }, remainingTime);
      }
    };

    // Debounce the fetch if it's a filter change
    const timeoutId = setTimeout(fetchListings, isUpdating ? 300 : 0);
    return () => clearTimeout(timeoutId);
  }, [queryState, updateMetadata]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-neutral-900">
      <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 w-full flex-1">
        {/* Header with Title, Search, Filters and Favorites - Redesigned */}
        <div className="py-4 sm:py-6 flex items-center justify-between relative z-[60]">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold tracking-tight text-blue-900 dark:text-white flex items-center">
              {/* <div id="propeller" className="absolute transform -translate-x-1/2" style={{top: '-2px',  scale: '0.75'}} /> */}
              <span className="font-extrabold tracking-tight ml-1">All</span>
              <span className="font-light ml-[5px] tracking-normal">the</span>
              <span className="font-extrabold tracking-tight ml-1">Planes</span>
              <span className="inline-flex items-center justify-center h-9 relative" aria-hidden="true">            
              </span>
            </h1>
          </div>
          
          <div className="flex items-center gap-2 sm:gap-3">
            {/* Heart icon */}
            <Link to="/favorites" className="flex items-center gap-1">
              <Icon name="Heart" size={20} className="text-red-500 fill-red-500" />
              <span className="text-sm font-medium">{favoritesCount}</span>
            </Link>
            
            {/* Search button */}
            {/* <ExpandableSearch 
              initialValue={queryState.search}
              onSearch={handleSearch}
            /> */}

            {/* Filter button - reference for proper styling */}
            <button
              onClick={() => setIsFilterModalOpen(true)}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'inline-flex items-center justify-center h-10 px-4 text-sm font-semibold bg-blue-800 hover:bg-blue-900 text-white'
              )}
            >
              <Icon name="Filter" size={16} className="mr-2" />
              Filters
            </button>
          </div>
        </div>

        <div className="relative z-[50]">          
          {/* Filter Pills Row - Desktop */}
          <div>
            <SearchAndFilter
              onFilterChange={handleFilterChange}
              availableFilters={availableFilters}
              currentFilters={queryState as unknown as QueryState}
              showZeroPrice={queryState.showZeroPrice}
              totalCount={metadata.total}
              onClearAll={clearFilters}
              hideFilterPills={false}
            />
          </div>
        </div>

        {/* Main Content */}
        <ErrorBoundary>
          <Suspense fallback={<LoadingIndicator />}>
            <div className="flex flex-col">
              {error ? (
                <div className="text-red-500">Error: {error}</div>
              ) : (
                <>
                  <AnimatePresence>
                    {(showSpinner) && (
                      <motion.div 
                        className="fixed inset-0 flex items-center justify-center bg-white/70 dark:bg-neutral-900/70 z-[45] backdrop-blur-[1px]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: animations.loading.duration }}
                      >
                        <FilterUpdateIndicator />
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <motion.div 
                    className={`
                      grid gap-x-6 gap-y-6 flex-1 pb-10 mb-14
                      ${columnCount === 1 ? 'grid-cols-1' : 
                        columnCount === 2 ? 'grid-cols-2' :
                        columnCount === 3 ? 'grid-cols-3' :
                        'grid-cols-4'}
                      relative
                    `}
                    layout
                    animate={{ opacity: isLoadingComplete ? 1 : 0.3 }}
                    transition={{ duration: animations.loading.duration }}
                  >
                    <AnimatePresence 
                      mode="sync" 
                      initial={false}
                      key={`listings-${queryState.page}-${queryState.sort}-${columnCount}`}
                    >
                      {listings.map((listing, index) => {
                        // Generate a stable key that only changes when the listing content changes
                        // Use a deterministic string that will only change when the listing is actually different
                        const stableKey = `${listing.aircraftId || listing._id}-${listing.price || 0}-${listing.title || ''}`;
                        
                        // Calculate a stagger delay that's both position and row-aware
                        // This creates a more natural wave effect
                        const row = Math.floor(index / (columnCount === 1 ? 1 : columnCount === 2 ? 2 : columnCount === 3 ? 3 : 4));
                        const col = index % (columnCount === 1 ? 1 : columnCount === 2 ? 2 : columnCount === 3 ? 3 : 4);
                        const staggerDelay = isLoadingComplete ? 
                          (row * 0.02 + col * 0.03) : 0;
                        
                        return (
                          <motion.div
                            key={stableKey}
                            initial={{ opacity: 0, y: animations.card.initialY }}
                            animate={{ 
                              opacity: isLoadingComplete ? 1 : 0,
                              y: isLoadingComplete ? 0 : animations.card.initialY 
                            }}
                            exit={{ opacity: 0 }}
                            transition={{ 
                              duration: animations.card.appear.duration,
                              delay: staggerDelay,
                              ease: animations.card.appear.ease
                            }}
                            layout
                          >
                            <ListingCard
                              listing={listing}
                              selectedAttributes={uiState.visibleMetrics}
                              onImageClick={handleImageClick}
                              isSelected={
                                (listing.aircraftId && listing.aircraftId === currentDetailId) || 
                                (listing._id === currentDetailId) || 
                                (listing.aircraftId && listing.aircraftId === recentlyClosedId) || 
                                (listing._id === recentlyClosedId)
                              }
                            />
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                  </motion.div>

                  {/* Pagination */}
                  <Pagination
                    currentPage={queryState.page}
                    totalPages={metadata.totalPages}
                    totalItems={metadata.total}
                    itemsPerPage={queryState.itemsPerPage}
                    visibleItems={listings.length}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </Suspense>
        </ErrorBoundary>

        {/* Image Modal */}
        {isModalOpen && currentListing && (
          <ImageModal
            firstImage={currentListing.firstImage}
            imageCount={currentListing.imageCount}
            listingId={currentListing.aircraftId || currentListing._id}
            initialIndex={modalIndex}
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
          />
        )}
        
        {/* Listing Detail Modal */}
        <ListingDetailModal 
          isOpen={isDetailModalOpen}
          listingId={currentDetailId || ''}
          onClose={(event) => handleCloseDetailModal(event as CustomEvent<{ fromBackButton?: boolean }>)}
          sourceCardRect={lastViewedCardRect}
        />

        {/* Metrics Configuration Modal */}
        {uiState.showMetricsConfig === 'true' && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    Attributes to Display
                  </h3>
                  <button
                    onClick={() => handleMetricsConfigToggle(false)}
                    className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                  >
                    <Icon name="X" size={16} className="text-gray-400 dark:text-neutral-500" />
                  </button>
                </div>

                <div className="space-y-6">
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">
                      Basic Information
                    </h4>
                    <div className="grid grid-cols-2 gap-3">
                      {availableMetrics
                        .filter(metric => metric.group === 'basic')
                        .map(metric => (
                          <label key={metric.key} className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={uiState.visibleMetrics.includes(metric.key)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleVisibleMetricsChange([...uiState.visibleMetrics, metric.key]);
                                } else {
                                  handleVisibleMetricsChange(uiState.visibleMetrics.filter(m => m !== metric.key));
                                }
                              }}
                              className="rounded text-blue-800 focus:ring-blue-800"
                            />
                            <span className="text-sm text-gray-900 dark:text-white">
                              {metric.label}
                            </span>
                          </label>
                        ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">
                      Equipment
                    </h4>
                    <div className="grid grid-cols-2 gap-3">
                      {availableMetrics
                        .filter(metric => metric.group === 'equipment')
                        .map(metric => (
                          <label key={metric.key} className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={uiState.visibleMetrics.includes(metric.key)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  handleVisibleMetricsChange([...uiState.visibleMetrics, metric.key]);
                                } else {
                                  handleVisibleMetricsChange(uiState.visibleMetrics.filter(m => m !== metric.key));
                                }
                              }}
                              className="rounded text-blue-800 focus:ring-blue-800"
                            />
                            <span className="text-sm text-gray-900 dark:text-white">
                              {metric.label}
                            </span>
                          </label>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                  <button
                    onClick={() => handleVisibleMetricsChange([...DEFAULT_METRICS])}
                    className="px-3 py-1 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                  >
                    Reset to Default
                  </button>
                  <button
                    onClick={() => handleMetricsConfigToggle(false)}
                    className="px-4 py-2 bg-blue-800 text-white rounded hover:bg-blue-900 text-sm font-medium"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Filter Modal */}
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          selectedMakes={filterHandlers.selectedMakes}
          selectedModelFamilies={filterHandlers.selectedModelFamilies}
          selectedModels={filterHandlers.selectedModels}
          ranges={{
            price: queryState.priceRange,
            year: queryState.yearRange,
            engineTime: queryState.engineTimeRange,
            usefulLoad: queryState.usefulLoadRange
          }}
          availableFilters={availableFilters}
          onMakeChange={filterHandlers.handleMakeChange}
          onModelFamilyChange={filterHandlers.handleModelFamilyChange}
          onModelChange={filterHandlers.handleModelChange}
          onRangeChange={filterHandlers.handleRangeChange}
          showZeroPrice={queryState.showZeroPrice}
          onShowZeroPriceChange={filterHandlers.handleShowZeroPriceChange}
          totalMatches={metadata.total}
          onClearAll={clearFilters}
          onFilterChange={handleFilterChange}
          equipment={queryState.equipment as Record<EquipmentCategory, boolean>}
          onEquipmentChange={filterHandlers.handleEquipmentChange}
          selectedCondition={filterHandlers.selectedCondition}
          selectedSaleStatuses={filterHandlers.selectedSaleStatuses}
          selectedIntendedUses={filterHandlers.selectedIntendedUses}
          selectedCategory={filterHandlers.selectedCategory}
          onConditionChange={filterHandlers.handleConditionChange}
          onSaleStatusChange={filterHandlers.handleSaleStatusChange}
          onIntendedUseChange={filterHandlers.handleIntendedUseChange}
          onCategoryChange={filterHandlers.handleCategoryChange}
          sort={queryState.sort}
          onSortChange={filterHandlers.handleSortChange}
          columnCount={columnCount}
          onLayoutChange={setColumnCount}
          onCustomizeClick={() => {
            setIsFilterModalOpen(false);
            handleMetricsConfigToggle(true);
          }}
          showSold={queryState.showSold}
          onShowSoldChange={filterHandlers.handleShowSoldChange}
        />
      </div>
    </div>
  );
};