import { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import * as React from 'react';
import { cn, pillStyles, buttonStyles, formatModelName, formatMakeName, formatTitleCase } from '../lib/utils';
import { Icon } from './Icon';
import RangeSlider from 'react-range-slider-input';
import { LayoutSwitcher } from './LayoutSwitcher';
import { DarkModeToggle } from './DarkModeToggle';
import { EquipmentFilter } from './EquipmentFilter';
// @ts-ignore - Suppress likely incorrect TS error after clean install
import NumberFlow, { useCanAnimate } from '@number-flow/react';
import { motion } from 'framer-motion';
import type { Option } from '../types/api';
import type { QueryState, SortOption } from '../types/queryState';
import { equipmentCategories } from '../types/api';
import type { EquipmentCategory } from '../types/api';
import { SelectDropdown, defaultFormatOptionLabel, SelectOption as DropdownOption } from './SelectDropdown';
import { getScrollbarWidth } from '../utils/scrollbarWidth';
import { RANGE_CONFIGS } from '../shared/range-config.js';
import { Switch } from '@headlessui/react';
import { useAccordionHeight } from '../hooks/useFilterModalHooks';

// Extended option types
type ModelFamilyOption = Option & { make?: string; modelFamily?: string };
type ModelOption = Option & { make?: string; modelFamily?: string };

// Create motion version of NumberFlow
const MotionNumberFlow = motion(NumberFlow);

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedMakes: Option[];
  selectedModelFamilies: ModelFamilyOption[];
  selectedModels: ModelOption[];
  ranges: {
    price: [number, number];
    year: [number, number];
    engineTime: [number, number];
    usefulLoad: [number, number];
  };
  availableFilters: {
    makes: Option[];
    modelFamilies: ModelFamilyOption[];
    models: ModelOption[];
    years: { min: number; max: number };
    conditions?: Option[];
    saleStatuses?: Option[];
    intendedUses?: Option[];
    categories?: Option[];
  };
  onMakeChange: (selected: Option | Option[] | null) => void;
  onModelFamilyChange: (selected: ModelFamilyOption | ModelFamilyOption[] | null) => void;
  onModelChange: (selected: ModelOption | ModelOption[] | null) => void;
  onRangeChange: (type: 'price' | 'year' | 'engineTime' | 'usefulLoad', values: [number, number]) => void;
  showZeroPrice: boolean;
  onShowZeroPriceChange: (checked: boolean) => void;
  totalMatches: number;
  onClearAll: () => void;
  onFilterChange: (filters: Partial<QueryState>) => void;
  equipment: Record<EquipmentCategory, boolean>;
  onEquipmentChange: (equipment: Record<EquipmentCategory, boolean>) => void;
  
  selectedCondition?: Option | null;
  selectedSaleStatuses: Option[];
  selectedIntendedUses: Option[];
  selectedCategory?: Option | null;
  onConditionChange: (selected: Option | null) => void;
  onSaleStatusChange: (selected: Option | Option[] | null) => void;
  onIntendedUseChange: (selected: Option | Option[] | null) => void;
  onCategoryChange: (selected: Option | null) => void;
  sort: SortOption;
  onSortChange: (sort: SortOption) => void;
  columnCount: 1 | 2 | 3 | 4;
  onLayoutChange: (count: 1 | 2 | 3 | 4) => void;
  onCustomizeClick: () => void;
  showSold: boolean;
  onShowSoldChange: (checked: boolean) => void;
}

// Add this utility function to safely create dropdown options
const createDropdownOption = (option: any): DropdownOption => {
  return {
    value: String(option.value || ''),
    label: String(option.label || option.value || ''),
    count: option.count || 0,
    disabled: !!option.disabled
  };
};

// Pill component for options
interface PillProps {
  label: string;
  count?: number;
  isSelected: boolean;
  onClick: () => void;
  disabled?: boolean;
  isModel?: boolean;
  isMake?: boolean;
}

const Pill = ({ label, count, isSelected, onClick, disabled = false, isModel = false, isMake = false }: PillProps) => {
  // Determine how to capitalize the label based on whether it's a model or make
  let capitalizedLabel;
  
  if (isModel) {
    // Special capitalization for model names
    capitalizedLabel = formatModelName(label);
  } else if (isMake) {
    // Special capitalization for make names
    capitalizedLabel = formatMakeName(label);
  } else {
    // Standard capitalization for non-model labels
    capitalizedLabel = formatTitleCase(label);
  }
  
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn(
        pillStyles.base,
        pillStyles.sizes.md,
        isSelected ? pillStyles.variants.active : pillStyles.variants.inactive,
        disabled && "opacity-50 cursor-not-allowed"
      )}
    >
      <span className="inline-flex items-center leading-none">
        <span className="leading-none">{capitalizedLabel}</span>
        {typeof count !== 'undefined' && count > 0 && (
          <span className={pillStyles.count}>({count})</span>
        )}
      </span>
    </button>
  );
};

// Pill group component for displaying multiple options
interface PillGroupProps {
  options: Array<Option & { disabled?: boolean }>;
  selectedValues: string[];
  onChange: (selected: Option[]) => void;
  maxDisplay?: number;
  emptyMessage?: string;
  isModelSection?: boolean;
  isMakeSection?: boolean;
}

const PillGroup = ({ 
  options, 
  selectedValues, 
  onChange, 
  maxDisplay = 1000,
  emptyMessage = "No options available",
  isModelSection = false,
  isMakeSection = false
}: PillGroupProps) => {
  // State to track if we're showing all options
  const [showAll, setShowAll] = useState(false);
  
  // Filter to only valid options with counts
  const validOptions = options.filter(opt => opt && typeof opt.value !== 'undefined' && opt.label);
  
  // Sort options based on section type
  const sortedOptions = [...validOptions].sort((a, b) => {
    // For model sections, prioritize alphabetical sorting
    if (isModelSection) {
      return (a.label || '').localeCompare(b.label || '');
    }
    
    // For other sections (including make), sort by count first, then alphabetically
    if ((b.count || 0) !== (a.count || 0)) {
      return (b.count || 0) - (a.count || 0);
    }
    // Then alphabetically
    return (a.label || '').localeCompare(b.label || '');
  });
  
  // Determine which options to display based on maxDisplay and showAll
  const displayOptions = showAll ? sortedOptions : sortedOptions.slice(0, maxDisplay);
  
  // Check if we need a "show more" button
  const hasMoreOptions = sortedOptions.length > maxDisplay;
  
  // Count selected options that would be hidden when collapsed
  const hiddenSelectedCount = !showAll 
    ? selectedValues.filter(value => 
        !displayOptions.some(opt => String(opt.value) === value)
      ).length
    : 0;
  
  // Handler for toggling a pill
  const handlePillClick = (option: Option) => {
    const value = String(option.value).toLowerCase();
    const isCurrentlySelected = selectedValues.includes(value);
    
    if (isCurrentlySelected) {
      // Remove from selection
      const newSelected = selectedValues.filter(v => v !== value);
      // Find matching option objects for the remaining selected values
      const selectedOptions = sortedOptions.filter(opt => 
        newSelected.includes(String(opt.value).toLowerCase())
      );
      onChange(selectedOptions);
    } else {
      // Add to selection
      const newSelected = [...selectedValues, value];
      // Get the current option being added - use case-insensitive matching
      const newOption = sortedOptions.find(opt => 
        String(opt.value).toLowerCase() === value
      );
      // Get existing selected options
      const existingOptions = sortedOptions.filter(opt => 
        selectedValues.includes(String(opt.value).toLowerCase())
      );
      // Combine existing options with the new one
      onChange(newOption ? [...existingOptions, newOption] : existingOptions);
    }
  };
  
  if (validOptions.length === 0) {
    return <p className="text-sm text-gray-500 dark:text-neutral-400 p-2">{emptyMessage}</p>;
  }
  
  return (
    <div className="flex flex-wrap -m-1">
      {displayOptions.map(option => (
        <Pill
          key={String(option.value)}
          label={String(option.label)}
          count={option.count}
          isSelected={selectedValues.includes(String(option.value).toLowerCase())}
          onClick={() => handlePillClick(option)}
          disabled={option.disabled}
          isModel={isModelSection}
          isMake={isMakeSection}
        />
      ))}
      
      {hasMoreOptions && (
        <button
          type="button"
          onClick={() => setShowAll(!showAll)}
          className={cn(
            pillStyles.base,
            pillStyles.sizes.md,
            pillStyles.variants.default,
            'border border-gray-200 dark:border-neutral-800'
          )}
        >
          {showAll ? (
            <span className="inline-flex items-center leading-none">
              Show Less
              {hiddenSelectedCount > 0 && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50">
                  {hiddenSelectedCount}
                </span>
              )}
            </span>
          ) : (
            <span className="inline-flex items-center leading-none">
              Show {sortedOptions.length - maxDisplay} More
              {hiddenSelectedCount > 0 && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50">
                  {hiddenSelectedCount}
                </span>
              )}
            </span>
          )}
        </button>
      )}
    </div>
  );
};

// Single selection pill group component
interface SinglePillGroupProps {
  options: Array<Option & { disabled?: boolean }>;
  selectedValue: string | null;
  onChange: (selected: Option | null) => void;
  maxDisplay?: number;
  emptyMessage?: string;
  isModelSection?: boolean;
  isMakeSection?: boolean;
}

const SinglePillGroup = ({ 
  options, 
  selectedValue, 
  onChange, 
  maxDisplay = 1000,
  emptyMessage = "No options available",
  isModelSection = false,
  isMakeSection = false
}: SinglePillGroupProps) => {
  // State to track if we're showing all options
  const [showAll, setShowAll] = useState(false);
  
  // Filter to only valid options with counts
  const validOptions = options.filter(opt => opt && typeof opt.value !== 'undefined' && opt.label);
  
  // Sort options based on section type
  const sortedOptions = [...validOptions].sort((a, b) => {
    // For model sections, prioritize alphabetical sorting
    if (isModelSection) {
      return (a.label || '').localeCompare(b.label || '');
    }
    
    // For other sections (including make), sort by count first, then alphabetically
    if ((b.count || 0) !== (a.count || 0)) {
      return (b.count || 0) - (a.count || 0);
    }
    // Then alphabetically
    return (a.label || '').localeCompare(b.label || '');
  });
  
  // Determine which options to display based on maxDisplay and showAll
  const displayOptions = showAll ? sortedOptions : sortedOptions.slice(0, maxDisplay);
  
  // Check if we need a "show more" button
  const hasMoreOptions = sortedOptions.length > maxDisplay;
  
  // Check if the selected value is in the hidden options
  const isSelectedHidden = !showAll && selectedValue !== null && 
    !displayOptions.some(opt => String(opt.value).toLowerCase() === selectedValue);
  
  // Handler for toggling a pill
  const handlePillClick = (option: Option) => {
    const value = String(option.value).toLowerCase();
    const isCurrentlySelected = selectedValue === value;
    
    if (isCurrentlySelected) {
      // Deselect
      onChange(null);
    } else {
      // Select new option
      onChange(option);
    }
  };
  
  if (validOptions.length === 0) {
    return <p className="text-sm text-gray-500 dark:text-neutral-400 p-2">{emptyMessage}</p>;
  }
  
  return (
    <div className="flex flex-wrap -m-1">
      {displayOptions.map(option => (
        <Pill
          key={String(option.value)}
          label={String(option.label)}
          count={option.count}
          isSelected={selectedValue === String(option.value).toLowerCase()}
          onClick={() => handlePillClick(option)}
          disabled={option.disabled}
          isModel={isModelSection}
          isMake={isMakeSection}
        />
      ))}
      
      {hasMoreOptions && (
        <button
          type="button"
          onClick={() => setShowAll(!showAll)}
          className={cn(
            pillStyles.base,
            pillStyles.sizes.md,
            pillStyles.variants.default,
            'border border-gray-200 dark:border-neutral-800'
          )}
        >
          {showAll ? (
            <span className="inline-flex items-center leading-none">
              Show Less
              {isSelectedHidden && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-700 dark:text-blue-50">
                  1
                </span>
              )}
            </span>
          ) : (
            <span className="inline-flex items-center leading-none">
              Show {sortedOptions.length - maxDisplay} More
              {isSelectedHidden && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-700 dark:text-blue-50">
                  1
                </span>
              )}
            </span>
          )}
        </button>
      )}
    </div>
  );
};

// Accordion component for filter sections
interface AccordionProps {
  title: string;
  count: number;
  icon?: React.ReactNode;
  defaultOpen?: boolean;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: (title: string) => void;
  isLast?: boolean;
}

const Accordion = ({ title, count, icon, children, isOpen, onToggle, isLast = false }: AccordionProps) => {
  // Use the custom hook to calculate dynamic height
  const { maxHeight, accordionRef, contentRef, headerRef } = useAccordionHeight(isOpen, title);
  
  // Check if NumberFlow can animate
  const canAnimate = useCanAnimate();

  return (
    <div 
      className={`${isLast ? '' : 'border-b border-gray-300 dark:border-neutral-700'}`}
      ref={accordionRef}
    >
      <div 
        className={`w-full accordion-header`}
        ref={headerRef}
      >
        <button
          onClick={() => onToggle(title)}
          className={`w-full flex items-center justify-between transition-colors ${
            isOpen 
              ? 'bg-gray-200 dark:bg-neutral-800 border-b border-gray-300 dark:border-neutral-700' 
              : 'bg-white dark:bg-neutral-900 hover:bg-gray-100 dark:hover:bg-neutral-800'
          }`}
          style={{
            padding: 'clamp(0.75rem, 2vh, 0.8rem) clamp(1rem, 3vh, 0.95rem)'
          }}
        >
          <div className="flex items-center">
            {icon && (
              <span 
                className="mr-2 text-gray-700 dark:text-neutral-300"
                style={{
                  width: 'clamp(1.25rem, 3vh, 1.15rem)',
                  height: 'clamp(1.25rem, 3vh, 1.15rem)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {React.cloneElement(icon as React.ReactElement, {
                  size: 'clamp(1.25rem, 3vh, 1.15rem)'
                })}
              </span>
            )}
            <h3 
              className="font-medium text-gray-900 dark:text-neutral-100"
              style={{
                fontSize: 'clamp(0.875rem, 2.5vh, 1.045rem)'
              }}
            >
              {title}
              {count > 0 && (
                <motion.span 
                  className="ml-2 inline-flex items-center justify-center rounded-full text-[10px] font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50"
                  style={{
                    width: 'clamp(1rem, 2.5vh, 0.95rem)',
                    height: 'clamp(1rem, 2.5vh, 0.95rem)',
                    fontSize: 'clamp(0.625rem, 1.5vh, 0.6rem)'
                  }}
                  layout={canAnimate}
                  transition={{ layout: { duration: 0.4, type: 'spring', bounce: 0 } }}
                >
                  <MotionNumberFlow 
                    value={count} 
                    className="accordion-count-badge"
                    transformTiming={{ duration: 800, easing: 'cubic-bezier(0.16, 1, 0.3, 1)' }}
                    layout={canAnimate}
                    layoutRoot={canAnimate}
                  />
                </motion.span>
              )}
            </h3>
          </div>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className={`transition-transform duration-500 ease-out ${isOpen ? 'transform rotate-180' : ''}`} 
            viewBox="0 0 20 20" 
            fill="currentColor"
            style={{
              width: 'clamp(1rem, 2.5vh, 0.95rem)',
              height: 'clamp(1rem, 2.5vh, 0.95rem)'
            }}
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      <div 
        className={`transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'opacity-100' : 'opacity-0 max-h-0'}`}
        style={{ maxHeight: isOpen ? maxHeight : 0 }}
      >
        <div className="relative">
          {/* Add permanent top shadow */}
          <div className="absolute top-0 left-0 right-0 h-6 pointer-events-none z-10">
            {/* Light mode shadow - hidden in dark mode */}
            <div 
              className="absolute inset-0 dark:hidden"
              style={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.02) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
            {/* Dark mode shadow */}
            <div 
              className="absolute inset-0 hidden dark:block"
              style={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.15) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
          </div>
          <div 
            className="bg-white dark:bg-neutral-950 overflow-y-auto"
            style={{ 
              maxHeight,
              padding: 'clamp(1.25rem, 3vh, 1.5rem) clamp(1.5rem, 4vh, 1.75rem) clamp(2rem, 4.5vh, 2.25rem)'
            }}
            ref={contentRef}
          >
            {children}
          </div>
          
          {/* Add permanent bottom shadow */}
          <div className="absolute bottom-0 left-0 right-0 h-6 pointer-events-none z-10">
            {/* Light mode shadow - hidden in dark mode */}
            <div 
              className="absolute inset-0 dark:hidden"
              style={{
                background: 'linear-gradient(to top, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.02) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
            {/* Dark mode shadow */}
            <div 
              className="absolute inset-0 hidden dark:block"
              style={{
                background: 'linear-gradient(to top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.15) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Define sortOptions for the dropdown
const sortOptions = [
  { value: 'relevance', label: 'Most Relevant' },
  { value: 'price-asc', label: 'Price: Low to High' },
  { value: 'price-desc', label: 'Price: High to Low' },
  { value: 'year-asc', label: 'Year: Oldest' },
  { value: 'year-desc', label: 'Year: Newest' },
  { value: 'date-listed', label: 'Recently Listed' },
  { value: 'updated-desc', label: 'Recently Updated' },
  { value: 'updated-asc', label: 'Oldest Updates' },
];

// Helper function to format sort options
const formatSortOptionLabel = (option: { value: string; label: string }) => (
  <div className="flex items-center py-2 px-3">
    <span>{option.label}</span>
  </div>
);

export const FilterModal = memo(({
  isOpen,
  onClose,
  selectedMakes,
  selectedModelFamilies,
  selectedModels,
  ranges,
  availableFilters,
  onMakeChange,
  onModelFamilyChange,
  onModelChange,
  onRangeChange,
  showZeroPrice,
  onShowZeroPriceChange,
  totalMatches,
  onClearAll,
  onFilterChange,
  equipment,
  onEquipmentChange,
  selectedCondition = null,
  selectedSaleStatuses = [],
  selectedIntendedUses = [],
  selectedCategory = null,
  onConditionChange,
  onSaleStatusChange,
  onIntendedUseChange,
  onCategoryChange,
  sort,
  onSortChange,
  columnCount,
  onLayoutChange,
  onCustomizeClick,
  showSold,
  onShowSoldChange
}: FilterModalProps) => {
  // Search state for filtering pills
  const [makeSearch, setMakeSearch] = useState('');
  const [modelFamilySearch, setModelFamilySearch] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  
  // State to track search input visibility
  const [showMakeSearch, setShowMakeSearch] = useState(false);
  const [showModelFamilySearch, setShowModelFamilySearch] = useState(false);
  const [showModelSearch, setShowModelSearch] = useState(false);
  
  // State for active accordion
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  
  // Refs for focusing search inputs
  const makeSearchRef = useRef<HTMLInputElement>(null);
  const modelFamilySearchRef = useRef<HTMLInputElement>(null);
  const modelSearchRef = useRef<HTMLInputElement>(null);
  
  // Local state for range values during dragging
  const [localRanges, setLocalRanges] = useState({
    price: ranges.price,
    year: ranges.year,
    engineTime: ranges.engineTime,
    usefulLoad: ranges.usefulLoad
  });

  // Effect to focus the search input when it becomes visible
  useEffect(() => {
    if (showMakeSearch && makeSearchRef.current) {
      makeSearchRef.current.focus();
    }
  }, [showMakeSearch]);
  
  useEffect(() => {
    if (showModelFamilySearch && modelFamilySearchRef.current) {
      modelFamilySearchRef.current.focus();
    }
  }, [showModelFamilySearch]);
  
  useEffect(() => {
    if (showModelSearch && modelSearchRef.current) {
      modelSearchRef.current.focus();
    }
  }, [showModelSearch]);

  // Keep local ranges in sync with prop ranges when props change
  useEffect(() => {
    setLocalRanges({
      price: ranges.price,
      year: ranges.year,
      engineTime: ranges.engineTime,
      usefulLoad: ranges.usefulLoad
    });
  }, [ranges]);

  // Track active filters count for each section
  const activeFilterCounts = useMemo(() => {
    return {
      aircraftType: [
        selectedCategory,
        ...selectedIntendedUses
      ].filter(Boolean).length,
      makeModelYear: [
        ...selectedMakes, 
        ...selectedModelFamilies, 
        ...selectedModels
      ].length + (
        ranges.year[0] !== RANGE_CONFIGS.yearRange.min || 
        ranges.year[1] !== RANGE_CONFIGS.yearRange.max ? 1 : 0
      ),
      priceConditionStatus: (
        (ranges.price[0] !== RANGE_CONFIGS.priceRange.min || 
         ranges.price[1] !== RANGE_CONFIGS.priceRange.max ? 1 : 0) +
        (showZeroPrice ? 1 : 0) +
        (showSold ? 1 : 0) +
        (selectedCondition ? 1 : 0) +
        (selectedSaleStatuses.length > 0 ? 1 : 0)
      ),
      specifications: (
        (ranges.engineTime[0] !== RANGE_CONFIGS.engineTimeRange.min || 
         ranges.engineTime[1] !== RANGE_CONFIGS.engineTimeRange.max ? 1 : 0) +
        (ranges.usefulLoad[0] !== RANGE_CONFIGS.usefulLoadRange.min || 
         ranges.usefulLoad[1] !== RANGE_CONFIGS.usefulLoadRange.max ? 1 : 0)
      ),
      equipment: Object.values(equipment).filter(Boolean).length
    };
  }, [
    selectedMakes, selectedModelFamilies, selectedModels, ranges,
    showZeroPrice, showSold, equipment, selectedCondition, selectedSaleStatuses,
    selectedIntendedUses, selectedCategory
  ]);

  // Memoize filtered makes - DO NOT filter by any selection
  const filteredMakes = useMemo(() => {
    if (!availableFilters?.makes?.length) return [];
    
    const search = makeSearch.toLowerCase().trim();
    
    // Display all makes, only filtering by search term
    return [...availableFilters.makes]
      .filter(make => !search || (make.label?.toLowerCase().includes(search)))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableFilters.makes, makeSearch]);

  // Memoize filtered model families - only filter by selected makes, never by selected models
  const filteredModelFamilies = useMemo(() => {
    if (!availableFilters?.modelFamilies?.length) {
      return [];
    }

    // If no makes are selected, return empty array (keep this behavior)
    if (!selectedMakes?.length) {
      return [];
    }

    const selectedMakeValues = selectedMakes.map(make => make.value?.toString().toUpperCase() || '');
    const search = modelFamilySearch.toLowerCase().trim();

    // Only filter by make, never by model selection
    return availableFilters.modelFamilies
      .filter(modelFamily => 
        modelFamily?.make && 
        selectedMakeValues.includes(modelFamily.make.toUpperCase()) &&
        (!search || modelFamily.label?.toLowerCase().includes(search))
      );
  }, [selectedMakes, availableFilters.modelFamilies, modelFamilySearch]);
  
  // Create a mapping between model family values and their corresponding make
  const modelFamilyToMakeMap = useMemo(() => {
    const map: Record<string, string> = {};
    
    if (availableFilters?.modelFamilies?.length) {
      availableFilters.modelFamilies.forEach(modelFamily => {
        if (modelFamily.value && modelFamily.make) {
          map[String(modelFamily.value).toUpperCase()] = modelFamily.make.toUpperCase();
        }
      });
    }
    
    return map;
  }, [availableFilters.modelFamilies]);
  
  // Create a mapping between model values and their corresponding model family and make
  const modelToFamilyMap = useMemo(() => {
    const map: Record<string, { modelFamily: string, make: string }> = {};
    
    if (availableFilters?.models?.length) {
      availableFilters.models.forEach(model => {
        if (model.value && model.modelFamily && model.make) {
          map[String(model.value).toUpperCase()] = {
            modelFamily: model.modelFamily.toUpperCase(),
            make: model.make.toUpperCase()
          };
        }
      });
    }
    
    return map;
  }, [availableFilters.models]);
  
  // Ensure all selected model families have their make property set
  const enhancedSelectedModelFamilies = useMemo(() => {
    return selectedModelFamilies.map(modelFamily => {
      if (modelFamily.make) return modelFamily;
      
      const make = modelFamilyToMakeMap[String(modelFamily.value).toUpperCase()];
      return make ? { ...modelFamily, make } : modelFamily;
    });
  }, [selectedModelFamilies, modelFamilyToMakeMap]);
  
  // Ensure all selected models have their model family and make properties set
  const enhancedSelectedModels = useMemo(() => {
    return selectedModels.map(model => {
      // Use type assertion to tell TypeScript about the optional properties
      const modelWithFamily = model as ModelOption;
      
      if (modelWithFamily.modelFamily && modelWithFamily.make) return modelWithFamily;
      
      const modelInfo = modelToFamilyMap[String(model.value).toUpperCase()];
      if (modelInfo) {
        return {
          ...model,
          modelFamily: modelInfo.modelFamily,
          make: modelInfo.make
        } as ModelOption;
      }
      
      return modelWithFamily;
    }) as Array<ModelOption>;
  }, [selectedModels, modelToFamilyMap]);

  // Memoize filtered models - filter normally by both make and model family, but don't filter by count
  const filteredModels = useMemo(() => {
    if (!availableFilters?.models?.length) {
      return [];
    }

    // If no model families are selected, return empty array
    if (!enhancedSelectedModelFamilies.length) {
      return [];
    }

    const selectedMakeValues = selectedMakes.map(make => make.value?.toString().toUpperCase() || '');
    const selectedModelFamilyValues = enhancedSelectedModelFamilies.map(modelFamily => modelFamily.value?.toString().toUpperCase() || '');
    const search = modelSearch.toLowerCase().trim();
    
    // Filter by selected makes and model families, but don't filter by count
    return availableFilters.models
      .filter(model => {
        return model?.make && 
               selectedMakeValues.includes(model.make.toUpperCase()) && 
               model.modelFamily && 
               selectedModelFamilyValues.includes(model.modelFamily.toUpperCase()) && 
               (!search || model.label?.toLowerCase().includes(search));
      });
  }, [selectedMakes, enhancedSelectedModelFamilies, availableFilters.models, modelSearch]);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClearAll = () => {
    onClearAll();
  };

  // Prevent scrolling in the background when drawer is open
  useEffect(() => {
    if (isOpen) {
      // Check if we actually have a scrollbar before applying compensation
      const hasScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
      // Get cached scrollbar width from our utility
      const scrollbarWidth = hasScrollbar ? getScrollbarWidth() : 0;
      
      // Store the current scroll position
      const scrollY = window.scrollY;
      
      // Disable scrolling on html element (not body)
      document.documentElement.style.overflow = 'hidden';
      
      // Add padding to compensate for scrollbar removal (prevents layout shift)
      if (hasScrollbar && scrollbarWidth > 0) {
        document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
      }
      
      return () => {
        // Re-enable scrolling when drawer closes
        document.documentElement.style.overflow = '';
        document.documentElement.style.paddingRight = '';
        
        // Restore scroll position if needed
        window.scrollTo(0, scrollY);
      };
    }
  }, [isOpen]);

  // Check if NumberFlow can animate
  const canAnimate = useCanAnimate();

  // Render range slider with formatted values
  const renderRangeSlider = (
    type: 'price' | 'year' | 'engineTime' | 'usefulLoad',
    label: string,
    formatMin: (val: number) => string,
    formatMax: (val: number) => string
  ) => {
    const config = 
      type === 'price' ? RANGE_CONFIGS.priceRange :
      type === 'year' ? RANGE_CONFIGS.yearRange :
      type === 'engineTime' ? RANGE_CONFIGS.engineTimeRange :
      RANGE_CONFIGS.usefulLoadRange;
      
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">{label}</h3>
          <div className="text-sm font-medium text-gray-500 dark:text-neutral-300">
            <span>{formatMin(localRanges[type][0])}</span>
            <span>-</span>
            <span>{formatMax(localRanges[type][1])}</span>
          </div>
        </div>
        <div className="py-2 px-1">
          <RangeSlider
            value={localRanges[type]}
            onInput={(values: number[]) => setLocalRanges(prev => ({ 
              ...prev, 
              [type]: values as [number, number] 
            }))}
            onThumbDragEnd={() => onRangeChange(type, localRanges[type])}
            min={config.min}
            max={config.max}
            step={config.step}
          />
        </div>
      </div>
    );
  };

  // Search input for filtering pills
  const renderSearchInput = (
    value: string, 
    onChange: (value: string) => void,
    placeholder: string,
    ref: React.RefObject<HTMLInputElement>
  ) => (
    <div className="relative mb-4">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full py-2 px-3 pr-8 border border-gray-300 dark:border-neutral-700 rounded-md text-[13px] focus:ring-blue-800 focus:border-blue-800 dark:bg-neutral-800 dark:text-neutral-200"
        ref={ref}
      />
      {value && (
        <button
          type="button"
          onClick={() => onChange('')}
          className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-neutral-300"
          aria-label="Clear search"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      )}
    </div>
  );

  // Render the Aircraft Type section content
  const renderAircraftTypeContent = () => (
    <div className="space-y-6">
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Category</h3>
          {selectedCategory && (
            <button
              onClick={() => onCategoryChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear
            </button>
          )}
        </div>
        <SinglePillGroup
          options={(availableFilters.categories || [])}
          selectedValue={selectedCategory?.value?.toString() || null}
          onChange={onCategoryChange}
          maxDisplay={10}
          emptyMessage="No category options available"
          isModelSection={false}
        />
      </div>
      
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Mission Type</h3>
          {selectedIntendedUses.length > 0 && (
            <button
              onClick={() => onIntendedUseChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
          </div>
        <PillGroup
          options={(availableFilters.intendedUses || [])}
          selectedValues={selectedIntendedUses.map(u => String(u.value))}
          onChange={onIntendedUseChange}
          maxDisplay={10}
          emptyMessage="No mission type options available"
          isModelSection={false}
        />
        </div>
    </div>
  );

  // Render the Make/Model/Year section content
  const renderMakeModelYearContent = () => (
            <div className="space-y-6">
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <div className="flex items-center">
            <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Make</h3>
            <button
              onClick={() => setShowMakeSearch(!showMakeSearch)}
              className={`ml-2 p-1.5 rounded-md text-[13px] ${
                showMakeSearch 
                  ? 'text-blue-800 dark:text-blue-400' 
                  : 'text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200'
              } transition-colors flex items-center gap-1.5 hover:bg-gray-100 dark:hover:bg-neutral-800`}
              aria-label={showMakeSearch ? "Hide search" : "Search makes"}
            >
              <Icon name="search" size={14} />
              <span className="font-medium">Search</span>
            </button>
          </div>
          {selectedMakes.length > 0 && (
            <button
              onClick={() => onMakeChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        {showMakeSearch && renderSearchInput(makeSearch, setMakeSearch, "Search makes...", makeSearchRef)}
        <PillGroup
          options={filteredMakes}
          selectedValues={selectedMakes.map(m => String(m.value))}
          onChange={onMakeChange}
          maxDisplay={10}
          emptyMessage="No makes available based on your filters"
          isModelSection={false}
          isMakeSection={true}
                      />
                    </div>

      <div className={`${selectedMakes.length === 0 ? 'opacity-60' : ''}`}>
        <div className="flex justify-between items-baseline mb-2">
          <div className="flex items-center">
            <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Model Family</h3>
            <button
              onClick={() => selectedMakes.length > 0 && setShowModelFamilySearch(!showModelFamilySearch)}
              className={`ml-2 p-1.5 rounded-md text-[13px] ${
                showModelFamilySearch 
                  ? 'text-blue-800 dark:text-blue-400' 
                  : 'text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200'
              } transition-colors flex items-center gap-1.5 hover:bg-gray-100 dark:hover:bg-neutral-800`}
              aria-label={showModelFamilySearch ? "Hide search" : "Search model families"}
              disabled={selectedMakes.length === 0}
            >
              <Icon name="search" size={14} />
              <span className="font-medium">Search</span>
            </button>
          </div>
          {selectedModelFamilies.length > 0 && (
            <button
              onClick={() => onModelFamilyChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        {selectedMakes.length === 0 ? (
          <p className="text-sm text-gray-500 dark:text-neutral-400 py-2">Please select at least one make first</p>
        ) : (
          <>
            {showModelFamilySearch && renderSearchInput(modelFamilySearch, setModelFamilySearch, "Search model families...", modelFamilySearchRef)}
            {selectedMakes.length === 1 ? (
              // Single make selected - flat list display
              <PillGroup
                options={filteredModelFamilies}
                selectedValues={enhancedSelectedModelFamilies.map(m => String(m.value))}
                onChange={onModelFamilyChange}
                maxDisplay={20}
                emptyMessage="No model families available based on your filters"
                isModelSection={true}
              />
            ) : (
              // Multiple makes selected - group by make
              <div className="space-y-4">
                {selectedMakes.map(make => {
                  const makeName = make.value?.toString().toUpperCase() || '';
                  
                  // Filter model families for current make, showing only those with counts
                  const makeModelFamilies = filteredModelFamilies.filter(
                    mf => mf.make?.toUpperCase() === makeName
                  );
                  
                  if (makeModelFamilies.length === 0) return null;
                  
                  // Get model families selected for this specific make
                  const makeSelectedValues = enhancedSelectedModelFamilies
                    .filter(mf => mf.make?.toUpperCase() === makeName)
                    .map(m => String(m.value));
                  
                  // Handler for model family selection within this make's section
                  const handleMakeModelFamilyChange = (selected: ModelFamilyOption[]) => {
                    // Remove existing selections for this make
                    const otherMakesModelFamilies = enhancedSelectedModelFamilies.filter(
                      mf => mf.make?.toUpperCase() !== makeName
                    );
                    
                    // Ensure the new selections have the make property set
                    const selectedWithMake = selected.map(option => ({
                      ...option,
                      make: makeName
                    }));
                    
                    // Combine with existing selections from other makes
                    onModelFamilyChange([...otherMakesModelFamilies, ...selectedWithMake]);
                  };
                  
                  return (
                    <div key={`make-${make.value}`} className="pt-2 first:pt-0">
                      <h4 className="text-xs uppercase font-semibold text-gray-700 dark:text-neutral-300 mb-2 border-b border-gray-200 dark:border-neutral-700 pb-1">
                        {make.label}
                      </h4>
                      <PillGroup
                        options={makeModelFamilies}
                        selectedValues={makeSelectedValues}
                        onChange={handleMakeModelFamilyChange}
                        maxDisplay={20}
                        emptyMessage=""
                        isModelSection={true}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
                </div>

      <div className={`${enhancedSelectedModelFamilies.length === 0 ? 'opacity-60' : ''}`}>
        <div className="flex justify-between items-baseline mb-2">
          <div className="flex items-center">
            <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Model</h3>
            <button
              onClick={() => enhancedSelectedModelFamilies.length > 0 && setShowModelSearch(!showModelSearch)}
              className={`ml-2 p-1.5 rounded-md text-[13px] ${
                showModelSearch 
                  ? 'text-blue-800 dark:text-blue-400' 
                  : 'text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200'
              } transition-colors flex items-center gap-1.5 hover:bg-gray-100 dark:hover:bg-neutral-800`}
              aria-label={showModelSearch ? "Hide search" : "Search models"}
              disabled={enhancedSelectedModelFamilies.length === 0}
            >
              <Icon name="search" size={14} />
              <span className="font-medium">Search</span>
            </button>
          </div>
          {selectedModels.length > 0 && (
            <button
              onClick={() => onModelChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        {enhancedSelectedModelFamilies.length === 0 ? (
          <p className="text-sm text-gray-500 dark:text-neutral-400 py-2">Please select at least one model family first</p>
        ) : (
          <>
            {showModelSearch && renderSearchInput(modelSearch, setModelSearch, "Search models...", modelSearchRef)}
            {selectedMakes.length === 1 && enhancedSelectedModelFamilies.length === 1 ? (
              // Single make and model family selected - flat list display
              <PillGroup
                options={filteredModels}
                selectedValues={enhancedSelectedModels.map(m => String(m.value))}
                onChange={onModelChange}
                maxDisplay={18}
                emptyMessage="No models available based on your filters"
                isModelSection={true}
              />
            ) : (
              // Multiple makes or model families - group by model family
              <div className="space-y-4">
                {enhancedSelectedModelFamilies.map(modelFamily => {
                  const familyValue = String(modelFamily.value).toUpperCase();
                  const makeValue = modelFamily.make?.toUpperCase() || '';
                  
                  // Get make label for this model family
                  const makeForFamily = selectedMakes.find(make => 
                    make.value?.toString().toUpperCase() === makeValue
                  );
                  const makeLabel = makeForFamily?.label || '';
                  
                  // Filter models for current model family
                  let familyModels = filteredModels.filter(
                    model => model.modelFamily?.toUpperCase() === familyValue
                  );
                  
                  // If there are no models that match the filter but we have models selected for this family,
                  // we should still show them in the list
                  const selectedModelsForFamily = enhancedSelectedModels.filter(
                    model => model.modelFamily?.toUpperCase() === familyValue
                  );
                  
                  // Find existing selected models that aren't in the filtered models
                  const selectedButFilteredOut = selectedModelsForFamily.filter(
                    selected => !familyModels.some(model => 
                      String(model.value).toUpperCase() === String(selected.value).toUpperCase()
                    )
                  );
                  
                  // Add these back to our family models list
                  if (selectedButFilteredOut.length > 0) {
                    // Add selected models that were filtered out back to the list
                    // Mark them as having 0 count to indicate they're not in the current result set
                    const selectedOptions = selectedButFilteredOut.map(model => ({
                      ...model,
                      count: 0
                    }));
                    
                    familyModels = [...familyModels, ...selectedOptions];
                  }
                  
                  // Get models selected for this specific model family
                  const familySelectedValues = enhancedSelectedModels
                    .filter(m => m.modelFamily?.toUpperCase() === familyValue)
                    .map(m => String(m.value));
                  
                  // Handler for model selection within this model family
                  const handleFamilyModelChange = (selected: ModelOption[]) => {
                    // Remove existing selections for this model family
                    const otherFamiliesModels = enhancedSelectedModels.filter( // Corrected source list
                      m => m.modelFamily?.toUpperCase() !== familyValue
                    );
                    
                    // Ensure the new selections have the model family and make properties set
                    const selectedWithFamily = selected.map(option => ({
                      ...option,
                      modelFamily: familyValue,
                      make: makeValue
                    }));
                    
                    // Combine with existing selections from other model families
                    onModelChange([...otherFamiliesModels, ...selectedWithFamily]);
                  };
                  
                  return (
                    <div key={`family-${modelFamily.value}`} className="pt-2 first:pt-0">
                      <h4 className="text-xs uppercase font-semibold text-gray-700 dark:text-neutral-300 mb-2 border-b border-gray-200 dark:border-neutral-700 pb-1">
                        {selectedMakes.length > 1 ? `${makeLabel} - ${modelFamily.label}` : modelFamily.label}
                      </h4>
                      {familyModels.length === 0 ? (
                        <p className="text-sm text-gray-500 dark:text-neutral-400 p-2">No models available for this selection</p>
                      ) : (
                        <PillGroup
                          options={familyModels}
                          selectedValues={familySelectedValues}
                          onChange={handleFamilyModelChange}
                          maxDisplay={8}
                          emptyMessage=""
                          isModelSection={true}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
                </div>

      <div>
        {renderRangeSlider(
          'year', 
          'Year', 
          (val) => String(val), 
          (val) => String(val)
        )}
                    </div>
                  </div>
  );

  // Render the Price/Condition/Status section content
  const renderPriceConditionStatusContent = () => (
    <div className="space-y-6">
      <div>
        {renderRangeSlider(
          'price', 
          'Price', 
          (val) => `$${val.toLocaleString()}`, 
          (val) => val === RANGE_CONFIGS.priceRange.max ? `$${val.toLocaleString()}+` : `$${val.toLocaleString()}`
        )}
        <div className="mt-4 flex items-center">
          <Switch
            checked={showZeroPrice}
            onChange={onShowZeroPriceChange}
            className={`${
              showZeroPrice ? 'bg-blue-800' : 'bg-gray-200 dark:bg-neutral-700'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                showZeroPrice ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="ml-2 text-sm text-gray-600 dark:text-neutral-400">
            Show listings without price
          </span>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Condition</h3>
          {selectedCondition && (
            <button
              onClick={() => onConditionChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear
            </button>
          )}
        </div>
        <SinglePillGroup
          options={(availableFilters.conditions || [])}
          selectedValue={selectedCondition?.value?.toString() || null}
          onChange={onConditionChange}
          maxDisplay={10}
          emptyMessage="No condition options available"
          isModelSection={false}
        />
      </div>
      
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Sale Status</h3>
          {selectedSaleStatuses.length > 0 && (
            <button
              onClick={() => onSaleStatusChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        <PillGroup
          options={(availableFilters.saleStatuses || []).filter(status => status.value !== 'Sold')}
          selectedValues={selectedSaleStatuses.map(status => String(status.value))}
          onChange={onSaleStatusChange}
          maxDisplay={10}
          emptyMessage="No sale status options available"
          isModelSection={false}
        />
        
        <div className="mt-6 flex items-center">
          <Switch
            checked={showSold}
            onChange={onShowSoldChange}
            className={`${
              showSold ? 'bg-blue-800' : 'bg-gray-200 dark:bg-neutral-700'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          >
            <span
              className={`${
                showSold ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="ml-2 text-sm text-gray-600 dark:text-neutral-400">
            Show listings that are sold
          </span>
        </div>
      </div>
    </div>
  );

  // Render the Specifications section content
  const renderSpecificationsContent = () => (
    <div className="space-y-6">
      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Engine Type</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>

      <div>
        {renderRangeSlider(
          'engineTime', 
          'Total Engine Time', 
          (val) => `${val} hrs`, 
          (val) => val === RANGE_CONFIGS.engineTimeRange.max ? `${val}+ hrs` : `${val} hrs`
        )}
      </div>

      <div>
        {renderRangeSlider(
          'usefulLoad', 
          'Useful Load', 
          (val) => `${val} lbs`, 
          (val) => val === RANGE_CONFIGS.usefulLoadRange.max ? `${val}+ lbs` : `${val} lbs`
        )}
      </div>

      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Seats</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>

      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Flight Rules</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>
    </div>
  );

  return (
    <div 
      className={`fixed inset-0 z-[60] overflow-hidden transition-opacity duration-500 ease-out ${
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
      onMouseDown={handleBackdropClick}
    >
      {/* Simple backdrop without blur - using layered semi-transparent backgrounds instead */}
      <div className="absolute inset-0" onMouseDown={handleBackdropClick}>
        {/* Base dark layer */}
        <div className="absolute inset-0 bg-black/50" onMouseDown={handleBackdropClick}></div>
        
        {/* Optional subtle texture for depth (very light) - make sure it doesn't block clicks */}
        {isOpen && (
          <div 
            className="absolute inset-0 bg-gradient-to-br from-black/5 to-transparent pointer-events-none"
          ></div>
        )}
      </div>
      
      {/* Drawer Container */}
      <div 
        className={cn(
          "fixed inset-y-0 right-0 w-full sm:w-[400px] md:w-[450px] lg:w-[500px] max-w-full bg-white dark:bg-neutral-900 shadow-xl transform transition-transform duration-500 ease-out overflow-hidden flex flex-col drawer-container", 
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 bg-white dark:bg-neutral-900 border-b border-gray-300 dark:border-neutral-800 drawer-header">
          <div className="flex items-center justify-between py-2 px-5">
            <h2 
              className="text-sm font-semibold text-gray-900 dark:text-neutral-100 flex-1 text-center"
              style={{
                fontSize: 'clamp(1.14rem, 3.25vh, 1.24rem)'
              }}
            >
              Refine Your Search
            </h2>
            <button
              onClick={onClose}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-neutral-800 text-gray-500 dark:text-neutral-400"
              aria-label="Close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        {/* Content Area with Accordions */}
        <div className="flex-1 overflow-y-auto">
          {/* Sort and Layout Accordion */}
          <Accordion 
            title="Sort and Layout" 
            count={0}
            isOpen={activeAccordion === "Sort and Layout"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="arrow-down-wide-narrow" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <div className="space-y-6">
              {/* Layout switcher, Theme toggle, and Card Attributes */}
              <div>
                <div className="flex justify-between items-baseline mb-2">
                  <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Layout</h3>
                </div>
                <div className="flex items-center gap-2">
                  <LayoutSwitcher
                    onLayoutChange={onLayoutChange}
                    variant="standard"
                  />
                  <div className="flex items-center gap-2">
                    <button
                      onClick={onCustomizeClick}
                      className={cn(
                        buttonStyles.base,
                        buttonStyles.rounded,
                        buttonStyles.shadow,
                        'flex items-center justify-center h-10 px-4'
                      )}
                      title="Customize card attributes"
                    >
                      <Icon name="settings" size={16} className="text-gray-500 dark:text-neutral-400 mr-2" />
                      <span className="text-sm">Card Attributes</span>
                    </button>
                    <DarkModeToggle variant="inline" />
                  </div>
                </div>
              </div>

              {/* Sort buttons */}
              <div>
                <div className="flex justify-between items-baseline mb-2">
                  <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Sort by</h3>
                </div>
                <div className="space-y-1">
                  {sortOptions.map((option) => (
                    <button
                      key={option.value}
                      onClick={() => onSortChange(option.value as SortOption)}
                      className={cn(
                        'inline-block text-left px-3 py-2 rounded-md text-sm transition-colors',
                        sort === option.value
                          ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300 font-medium'
                          : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-800'
                      )}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </Accordion>

          <Accordion 
            title="Aircraft Type" 
            count={activeFilterCounts.aircraftType}
            isOpen={activeAccordion === "Aircraft Type"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="plane-takeoff" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            {renderAircraftTypeContent()}
          </Accordion>
          
          <Accordion 
            title="Make / Model / Year" 
            count={activeFilterCounts.makeModelYear}
            isOpen={activeAccordion === "Make / Model / Year"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="cog" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            {renderMakeModelYearContent()}
          </Accordion>
          
          <Accordion 
            title="Price / Condition / Status" 
            count={activeFilterCounts.priceConditionStatus}
            isOpen={activeAccordion === "Price / Condition / Status"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="circle-dollar-sign" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            {renderPriceConditionStatusContent()}
          </Accordion>
          
          <Accordion 
            title="Specifications" 
            count={activeFilterCounts.specifications}
            isOpen={activeAccordion === "Specifications"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="sliders-horizontal" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            {renderSpecificationsContent()}
          </Accordion>
          
          <Accordion 
            title="Avionics and Equipment" 
            count={activeFilterCounts.equipment}
            isOpen={activeAccordion === "Avionics and Equipment"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="server" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
            isLast={true}
          >
            <EquipmentFilter
              selectedEquipment={equipment}
              onEquipmentChange={onEquipmentChange}
              titleClassName="text-base font-medium text-gray-900 dark:text-neutral-100"
            />
          </Accordion>
        </div>
        
        {/* Footer with results and action buttons */}
        <div className="sticky bottom-0 z-10 bg-white dark:bg-neutral-900 border-t border-gray-200 dark:border-neutral-800 drawer-footer shadow-lg">
          <div className="py-1 px-4">
            <div className="flex items-center justify-between">
              <span 
                className="flex items-center"
              >
                <motion.span 
                  className="flex items-center"
                  layout={canAnimate}
                  transition={{ layout: { duration: 0.6, type: 'spring', bounce: 0 } }}
                >
                  <span className="footer-count-container">
                    <MotionNumberFlow 
                      value={totalMatches} 
                      format={{ useGrouping: true }}
                      className="footer-matches-count"
                      transformTiming={{ duration: 1000, easing: 'cubic-bezier(0.16, 1, 0.3, 1)' }}
                      layout={canAnimate}
                      layoutRoot={canAnimate}
                    /> 
                    <span className="ml-1 text-sm text-gray-600 dark:text-neutral-400 matches-label">
                      {totalMatches === 1 ? 'match' : 'matches'}
                    </span>
                  </span>
                </motion.span>
              </span>
              
              <div className="flex items-center gap-3">
                <button
                  onClick={handleClearAll}
                  className="px-3 py-2 bg-gray-100 hover:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-gray-700 dark:text-neutral-200 text-sm font-medium rounded-md transition-colors"
                >
                  Clear All
                </button>
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-blue-800 hover:bg-blue-900 dark:bg-blue-800 dark:hover:bg-blue-900 text-white text-sm font-medium rounded-md transition-colors"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Range Slider Styles */}
      <style>{`
        .range-slider {
          height: 4px !important;
          padding: 0 !important;
          background: var(--tw-neutral-200) !important;
        }
        
        .dark .range-slider {
          background: var(--tw-neutral-800) !important;
        }
        
        .range-slider .range-slider__range {
          background: var(--tw-blue-800) !important;
          height: 4px !important;
        }
        
        .range-slider .range-slider__thumb {
          width: 1.25rem !important;
          height: 1.25rem !important;
          background: var(--tw-white) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
        }

        .dark .range-slider .range-slider__thumb {
          background: var(--tw-neutral-100) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4) !important;
        }
        
        /* NumberFlow custom styling */
        .footer-matches-count {
          --number-flow-char-height: 1.5em;
          --number-flow-mask-height: 0.25em;
          font-size: 2rem !important;
          font-weight: 700 !important;
          color: #1e3a8a !important; /* blue-900 */
          letter-spacing: -0.02em;
          text-shadow: 0 1px 2px rgba(0,0,0,0.1);
          line-height: 1;
        }
        
        .dark .footer-matches-count {
          color: #3b82f6 !important; /* blue-500 */
          text-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
        
        .footer-count-container {
          display: flex;
          align-items: center;
        }
        
        .matches-label {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          position: relative;
          top: 0;
          line-height: 1;
          padding-bottom: 2px;
        }
        
        .accordion-count-badge {
          --number-flow-char-height: 0.625rem;
          --number-flow-mask-height: 0.1em;
        }
      `}</style>
    </div>
  );
}); // Restore memo wrapper
